.navbar-header {
	height: auto !important;
}
.navbar-brand {
	max-width: 182px;
	position: relative;
	white-space: normal;
}

.navbar-brand .brand-logo img {
	height: auto;
	max-height: 30px;
	max-width: 30px;
	width: auto;
}

.navbar-brand .brand-text {
	width: calc(100% - 30px);
}

.main-menu-content .navigation.navigation-main li:hover a {
	color: var(--primary);
	font-weight: 700;
}

.header-navbar.main-header-navbar.not-top {
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(25, 42, 70, 0.13) -8px 12px 18px 0px;
}

.bg-authentication {
	background: #f2f4f4;
}
body.not-authorized .card {
	background: transparent !important;
	box-shadow: none !important;
}

@media (max-width: 660px) {
	.content-wrapper {
		padding: 1.8rem 4px !important;
	}
}

.content-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-link {
	border: none;
	display: inline-block;
	color: none;
	vertical-align: top;
}

.btn-link:focus {
	outline: none;
}

.display-flex {
	display: flex;
}

.space-between {
	justify-content: space-between;
}

tr.loading td {
	transition: 200ms;
	color: rgba(0, 0, 0, 0.24);
}
tr.loading td > i.spin {
	font-size: 2rem;
}

tr.voided td {
	text-decoration: line-through;
}

.react-autosuggest__container--open .react-autosuggest__input {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
	background-color: var(--white);
	border: 1px solid #aaa;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	display: block;

	font-weight: 300;
	width: 280px;
	z-index: 2;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 4px 8px;
}

.react-autosuggest__suggestion:not(:first-child) {
	border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
	background-color: #0c7eaf;
	color: #fff;
}

button.nav-link-label {
	border: none;
	background: none;
}

@media (max-width: 620px) {
	.heading-elements {
		position: relative !important;
		margin-top: 1rem !important;
		top: 0 !important;
		right: 0 !important;
	}
}

body ::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

body ::-webkit-scrollbar-thumb {
	background: var(--gray);
	border-radius: 4px;
}

body ::-webkit-scrollbar-thumb:hover {
	background: var(--gray-dark);
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

body ::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

body ::-webkit-scrollbar-track {
	background: white;
	border-radius: 4px;
}

body ::-webkit-scrollbar-track:hover,
body ::-webkit-scrollbar-track:active {
	background: white;
}

img.round {
	object-fit: cover;
}

video {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg); /* Safari and Chrome */
	-moz-transform: rotateY(180deg); /* Firefox */
}
.video-container {
	height: 100%;
	width: 100%;
}
.video-container .main-video {
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
	transition: opacity 1s;
	transform: none !important;
	width: 100%;
}

.video-container .second-video {
	background: transparent;
	bottom: 1rem;
	height: auto;
	max-height: 30vh;
	max-width: 30vh;
	min-height: 25vh;
	min-width: 25vh;
	object-fit: cover;
	position: absolute;
	right: 1rem;
	width: 40%;
	z-index: 4;
}

.video-chat-room-upload-animation {
	position: absolute;
	bottom: 260px;
	left: 7rem;
	z-index: 3;
}

.video-chat-room-upload-animation > i {
	color: rgba(255, 255, 255, 0.24);
}

table tr > td > div.td-actions {
	display: none;
	padding: 0;
	transition: 200ms;
	width: 0;
}
table tr > td > div.td-actions button {
	font-size: 8px;
	margin-right: 4px;
	padding: 0 4px;
	text-transform: uppercase;
	width: 58px;
}
table tr > td > div.td-actions button i {
	display: block;
	font-size: 30px;
}

table tr:hover > td > div.td-actions {
	display: block;
	width: 124px;
}

.user-tab-container div.active {
	display: block !important;
}

.dark-mode-switch {
	margin-right: 12px;
}
.dark-mode-switch > .react-switch-bg {
	background: none !important;
	box-shadow: 1px 1px 5px var(--gray);
}

.dark-mode-switch > .react-switch-bg > div {
	align-items: center;
	display: flex !important;
	justify-content: center;
}

.dark-mode-switch .react-switch-handle {
	background: var(--gray) !important;
}

.hide {
	display: none !important;
}

.frame-container {
	height: 100%;

	position: relative;
	width: 100%; /* enlarge beyond browser width */
}

.frame-container .player-top-band {
	background: black;
	position: absolute;
	height: 4em;
	width: 100%;
}

.frame-container .player-bottom-band {
	background: black;
	bottom: 0;
	position: absolute;
	height: 3em;
	width: 100%;
}

.p-relative {
	position: relative;
}

body.dark-layout .chat-line .chat-message p {
	color: white;
}

body.dark-layout .chat-line.left .chat-message p {
	color: #8a99b5;
}
